import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'; // Corrected import
import router from './router';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

// Define your messages for each language
const messages = {
    en: {
        message: {
            "welcome": 'Welcome to AmanLink',
            "welcome2": 'You will get 3 days of free experience.',
            "welcome14": 'You will get 14 days of free experience.',
            "welcomeMsg": 'The vpn that works for you.',
            "email": 'Insert your email to start',
            "hint_email": 'Email',
            "download": 'Download From GooglePlay',
            "download2": 'Download Apk File',
            "msg_email_exist": 'This email address has already registered',
            "invitation_invalid": 'Invitation invalid',
            "invitation_used_up": 'Invitation all used',
            "msg_download_other_platform": 'Invite success.',

            "title_account": 'Delete your account in AmanLink',
            "desc_account": 'By providing your email address & password, you can delete your account in AmanLink. Your data will be deleted permanently.',
            "hint_password": 'Password',
            "delete": 'Delete Account',
            "msg_delete_success": 'Your account has been deleted.',
            "msg_validate_error": 'Account validation failed',

            "buy_vip": "Buy VIP",
            "sendToFriend": "Purchase a VIP activation code ➔ Gift it to a friend to redeem VIP duration",
            "buyAmount": "Purchase Quantity",
            "format_day": "Day(s)",
            "format_month": "Month(s)",
            "format_year": "Year",
            "format_sumary": "Equivalent to",

            "pay_payeer_1": "Pay with Payeer",
            "pay_payeer_2": "USDT/Litecoin/Bitcoin etc.",
            "pay_stripe_1": "Pay with Stripe",
            "pay_stripe_2": "VISA/MASTER CARD etc.",
            "pay_webmoney_1": "Pay with WebMoney",
            "pay_webmoney_2": "WMZ/RUSSIA CARD etc.",


            "pay_success": "Payment Success",
            "pay_fail": "Payment Failed",
            "column_activation_code": "Activation Code",
            "column_days": "Days",

            "toast_webmoney_limit": "When using WebMoney for payment, please be aware that there may be limits. If the payment fails, please try reducing the amount.",

            "title": "AmanLink VPN is Recruiting Local Resellers",
            "greeting": "Dear Potential Reseller Partner",
            "introduction": "We are excited to invite you to become a local reseller for our software activation code product. As a reseller, you will have the opportunity to purchase activation codes from us at a discounted rate and resell them in your local currency to local users. Below are the benefits of joining our reseller program, along with the requirements for partnership.",
            "benefitsTitle": "Partnership Benefits",
            "directoryPromotion": "Sales Directory Promotion",
            "directoryDetails": "You will be listed in our local reseller directory, allowing local users to find you directly when they need to purchase activation codes.",
            "rebateProgram": "Rebate Program",
            "rebateDetails": "Resellers will benefit from our unique rebate incentive program: Purchase 10 activation codes and receive 2 additional codes for free. Purchase 100 activation codes and receive 30 additional codes for free. This rebate program offers you a larger profit margin, reduced costs, and a competitive edge in your market.",
            "requirementsTitle": "Partnership Requirements",
            "quickResponse": "Prompt Response",
            "responseDetails": "You should respond to user requests promptly to ensure a positive purchase experience.",
            "friendlyCommunication": "Friendly Communication",
            "communicationDetails": "You should maintain good relationships with customers and assist them in resolving any issues they may encounter during purchase or use.",
            "localCurrency": "Local Currency Transactions",
            "currencyDetails": "As a reseller, you must support local currency transactions, not requiring customers to use foreign currency or cryptocurrency.",
            "policyTitle": "Partnership Policy",
            "policyDetails": "To maintain service quality, if a reseller receives excessive customer complaints, we will terminate the partnership and remove them from the local reseller directory.",
            "contactInfo": "If you are interested in becoming one of our local resellers, please contact our administrator {'@'}normanou (Telegram) or email us at mobile221106{'@'}gmail.com.",

            "load_failed": "Load failed",
            "load_retry": "Retry",
            "vip_charged_success": "VIP recharge successful. Please check your account.",
        }
    },
    zh: {
        "message": {
            "welcome": "欢迎来到AmanLink",
            "welcome2": "您将获得3天的免费体验。",
            "welcome14": "您将获得14天的免费体验。",
            "welcomeMsg": '适合您的VPN',

            "email": "输入您的电子邮件以开始",
            "hint_email": "电子邮件",
            "download": "从GooglePlay下载",
            "download2": "下载Apk文件",
            "msg_email_exist": "该电子邮件地址已经注册",
            "invitation_invalid": "邀请无效",
            "invitation_used_up": "邀请已全部使用",
            "msg_download_other_platform": "邀请成功。",

            "title_account": "删除您在AmanLink中的账户",
            "desc_account": "提供您的电子邮件地址和密码，您可以删除在AmanLink中的账户。您的数据将被永久删除。",
            "hint_password": "密码",
            "delete": "删除账户",
            "msg_delete_success": "您的账户已被删除。",
            "msg_validate_error": "账户验证失败",

            "buy_vip": "购买会员",
            "sendToFriend": "帮购VIP激活码 ➔ 赠朋友可兑VIP时长",
            "buyAmount": "购买数量",
            "format_day": "天",
            "format_month": "个月",
            "format_year": "年",
            "format_sumary": "折合",

            "pay_payeer_1": "使用 Payeer 支付",
            "pay_payeer_2": "USDT/莱特币/比特币等",
            "pay_stripe_1": "使用 Stripe 支付",
            "pay_stripe_2": "VISA/万事达卡等",
            "pay_webmoney_1": "使用 WebMoney 支付",
            "pay_webmoney_2": "WMZ/俄罗斯卡等",

            "pay_success": "支付成功",
            "pay_fail": "支付失败",
            "column_activation_code": "激活码",
            "column_days": "天",

            "toast_webmoney_limit": "使用 WebMoney 支付时，请注意可能存在限额。如果支付失败，请尝试减少支付数量。",

            "title": "AmanLink VPN 正在招募本地经销商",
            "greeting": "尊敬的潜在经销商伙伴",
            "introduction": "我们公司现诚邀您成为我们软件激活码产品的本地经销商。作为经销商，您将从我们这里以优惠的价格购买激活码，并以当地货币转售给当地用户。以下是加入我们的经销商计划的详细信息，包括您将享受到的好处及我们的合作要求。",
            "benefitsTitle": "合作优势",
            "directoryPromotion": "销售名录推广",
            "directoryDetails": "我们将把您加入本地经销商名录，当地用户在需要购买激活码时，可以直接在名录中找到您。",
            "rebateProgram": "返点机制",
            "rebateDetails": "经销商将享受我们独特的返点激励政策：采购10个激活码，额外赠送2个。采购100个激活码，额外赠送30个。此返点机制将帮助您增加利润空间，降低成本，提高市场竞争力。",
            "requirementsTitle": "合作要求",
            "quickResponse": "快速响应",
            "responseDetails": "能够及时回应用户的购买请求，保障用户的购买体验。",
            "friendlyCommunication": "友好沟通",
            "communicationDetails": "与客户保持良好的沟通关系，帮助他们解决购买和使用过程中的问题。",
            "localCurrency": "本地货币交易",
            "currencyDetails": "作为经销商，你必须支持使用本地货币交易，而不是要求客户使用外币或者加密货币支付。",
            "policyTitle": "合作规范",
            "policyDetails": "为保障服务质量，如果您接到过多用户投诉，我们将终止合作，并将您从本地经销商名录中剔除。",
            "contactInfo": "如果您有意成为我们的本地经销商，请联系管理员 {'@'}normanou (Telegram) 或发送邮件至 mobile221106{'@'}gmail.com",

            "load_failed": "加载失败",
            "load_retry": "重试",
            "vip_charged_success": "VIP充值成功，请检查您的账户。"
        }
    },
    ar: {
        "message": {
            "welcome": "مرحبا بك في AmanLink",
            "welcome2": "ستحصل على تجربة مجانية لمدة 3 أيام.",
            "welcome14": "ستحصل على تجربة مجانية لمدة14 أيام.",
            "welcomeMsg": 'الشبكة الافتراضية الخاصة التي تناسبك.',
            "email": "أدخل بريدك الإلكتروني للبدء",
            "hint_email": "البريد الإلكتروني",
            "download": "تحميل من جوجل بلاي",
            "download2": "تنزيل ملف Apk",
            "msg_email_exist": "عنوان البريد الإلكتروني هذا مسجل بالفعل",
            "invitation_invalid": "الدعوة غير صالحة",
            "invitation_used_up": "تم استخدام جميع الدعوات",
            "msg_download_other_platform": "نجاح الدعوة.",

            "title_account": "حذف حسابك في AmanLink",
            "desc_account": "بتقديم عنوان بريدك الإلكتروني وكلمة المرور، يمكنك حذف حسابك في AmanLink. سيتم حذف بياناتك بشكل دائم.",
            "hint_password": "كلمة المرور",
            "delete": "حذف الحساب",
            "msg_delete_success": "تم حذف حسابك.",
            "msg_validate_error": "فشل في التحقق من الحساب",

            "buy_vip": "شراء VIP",
            "sendToFriend": "اشترِ رمز تفعيل VIP ➔ قدّمه لصديق لاستبدال مدة VIP",
            "buyAmount": "كمية الشراء",
            "format_day": "يوم",
            "format_month": "شهر",
            "format_year": "سنة",
            "format_sumary": "ما يعادل",

            "pay_payeer_1": "الدفع باستخدام Payeer",
            "pay_payeer_2": "USDT/لايتكوين/بيتكوين إلخ.",
            "pay_stripe_1": "الدفع باستخدام Stripe",
            "pay_stripe_2": "فيزا/ماستر كارد إلخ.",
            "pay_webmoney_1": "الدفع باستخدام WebMoney",
            "pay_webmoney_2": "WMZ/بطاقة روسيا إلخ.",


            "pay_success": "تم الدفع بنجاح",
            "pay_fail": "فشل الدفع",
            "column_activation_code": "رمز التفعيل",
            "column_days": "أيام",

            "toast_webmoney_limit": "عند استخدام WebMoney للدفع، يرجى ملاحظة أنه قد تكون هناك حدود. إذا فشل الدفع، يرجى محاولة تقليل المبلغ.",

            "load_failed": "فشل التحميل",
            "load_retry": "إعادة المحاولة",
            "vip_charged_success": "تم شحن VIP بنجاح. يرجى التحقق من حسابك."
        }
    },
    ru: {
        "message": {
            "welcome": "Добро пожаловать в AmanLink",
            "welcome2": "Вы получите 3 дня бесплатного опыта.",
            "welcome14": "Вы получите 14 дня бесплатного опыта.",
            "welcomeMsg": 'VPN, который работает для вас.',
            "email": "Введите вашу электронную почту, чтобы начать",
            "hint_email": "Электронная почта",
            "download": "Скачать с Google Play",
            "download2": "Скачать файл Apk",
            "msg_email_exist": "Этот адрес электронной почты уже зарегистрирован",
            "invitation_invalid": "Приглашение недействительно",
            "invitation_used_up": "Все приглашения использованы",
            "msg_download_other_platform": "Приглашение успешно.",

            "title_account": "Удалить ваш аккаунт в AmanLink",
            "desc_account": "Предоставив ваш адрес электронной почты и пароль, вы можете удалить ваш аккаунт в AmanLink. Ваши данные будут удалены навсегда.",
            "hint_password": "Пароль",
            "delete": "Удалить аккаунт",
            "msg_delete_success": "Ваш аккаунт был удален.",
            "msg_validate_error": "Проверка аккаунта не удалась",

            "buy_vip": "Купить VIP",
            "sendToFriend": "Купите код активации VIP ➔ Подарите другу, чтобы он активировал срок VIP",
            "buyAmount": "Количество покупки",
            "format_day": "День",
            "format_month": "Месяц",
            "format_year": "Год",
            "format_sumary": "Эквивалентно",

            "pay_payeer_1": "Оплатить через Payeer",
            "pay_payeer_2": "USDT/Лайткоин/Биткоин и др.",
            "pay_stripe_1": "Оплатить через Stripe",
            "pay_stripe_2": "VISA/MASTER CARD и др.",
            "pay_webmoney_1": "Оплатить через WebMoney",
            "pay_webmoney_2": "WMZ/Карта России и др.",

            "pay_success": "Оплата успешна",
            "pay_fail": "Ошибка оплаты",
            "column_activation_code": "Код активации",
            "column_days": "Дни",

            "toast_webmoney_limit": "При использовании WebMoney для оплаты, пожалуйста, имейте в виду, что могут быть ограничения. Если оплата не прошла, попробуйте уменьшить сумму.",

            "title": "AmanLink VPN набирает местных реселлеров",
            "greeting": "Уважаемый потенциальный партнер",
            "introduction": "Мы приглашаем вас стать местным реселлером для нашего продукта - кода активации программного обеспечения. Как реселлер, вы сможете приобретать коды активации у нас по сниженной цене и продавать их местным пользователям за местную валюту. Ниже приведены преимущества присоединения к нашей программе реселлеров и требования к партнёрству.",
            "benefitsTitle": "Преимущества Партнёрства",
            "directoryPromotion": "Рекламное Размещение в Каталоге",
            "directoryDetails": "Мы добавим вас в каталог местных реселлеров, чтобы местные пользователи могли найти вас напрямую, когда им потребуется приобрести коды активации.",
            "rebateProgram": "Система Скидок",
            "rebateDetails": "Реселлеры смогут воспользоваться нашей уникальной системой скидок: При покупке 10 кодов активации получаете 2 дополнительных кода бесплатно. При покупке 100 кодов активации получаете 30 дополнительных кодов бесплатно. Эта система скидок поможет вам увеличить прибыль, снизить затраты и повысить конкурентоспособность на рынке.",
            "requirementsTitle": "Требования к Партнёрству",
            "quickResponse": "Быстрая Реакция",
            "responseDetails": "Вы должны оперативно реагировать на запросы пользователей, чтобы обеспечить положительный опыт покупки.",
            "friendlyCommunication": "Дружелюбное Общение",
            "communicationDetails": "Вы должны поддерживать хорошие отношения с клиентами и помогать им решать любые проблемы, с которыми они могут столкнуться при покупке или использовании.",
            "localCurrency": "Поддержка Местной Валюты",
            "currencyDetails": "Как реселлер, вы должны поддерживать транзакции в местной валюте, не требуя от клиентов использования иностранной валюты или криптовалюты.",
            "policyTitle": "Политика Партнёрства",
            "policyDetails": "Чтобы поддерживать качество сервиса, при чрезмерном количестве жалоб от клиентов мы прекращаем партнёрство и удаляем реселлера из местного каталога.",
            "contactInfo": "Если вы заинтересованы стать нашим местным реселлером, свяжитесь с администратором {'@'}normanou (Telegram) или напишите нам на mobile221106{'@'}gmail.com.",

            "load_failed": "Ошибка загрузки",
            "load_retry": "Повторить",
            "vip_charged_success": "VIP пополнение успешно. Пожалуйста, проверьте свой аккаунт."
        }
    },
    fa: {
        "message": {
            "welcome": "به AmanLink خوش آمدید",
            "welcome2": "شما 3 روز تجربه رایگان خواهید داشت.",
            "welcome14": "شما 14 روز تجربه رایگان خواهید داشت.",
            "welcomeMsg": 'وی‌پی‌انی که برای شما کار می‌کند.',
            "email": "برای شروع ایمیل خود را وارد کنید",
            "hint_email": "ایمیل",
            "download": "از گوگل پلی دانلود کنید",
            "download2": "دانلود فایل Apk",
            "msg_email_exist": "این آدرس ایمیل قبلا ثبت شده است",
            "invitation_invalid": "دعوت نامعتبر است",
            "invitation_used_up": "تمام دعوت‌ها استفاده شده‌اند",
            "msg_download_other_platform": "دعوت موفقیت‌آمیز بود.",

            "title_account": "حذف حساب کاربری شما در AmanLink",
            "desc_account": "با ارائه آدرس ایمیل و رمز عبور خود، می‌توانید حساب کاربری خود را در AmanLink حذف کنید. داده‌های شما به طور دائم حذف خواهند شد.",
            "hint_password": "رمز عبور",
            "delete": "حذف حساب",
            "msg_delete_success": "حساب کاربری شما حذف شد.",
            "msg_validate_error": "اعتبارسنجی حساب ناموفق بود",

            "buy_vip": "خرید VIP",
            "sendToFriend": "کد فعال‌سازی VIP بخرید ➔ آن را به دوستی هدیه دهید تا مدت VIP را دریافت کند",
            "buyAmount": "تعداد خرید",
            "format_day": "روز",
            "format_month": "ماه",
            "format_year": "سال",
            "format_sumary": "معادل",

            "pay_payeer_1": "پرداخت با Payeer",
            "pay_payeer_2": "USDT/لایت کوین/بیت کوین و غیره.",
            "pay_stripe_1": "پرداخت با Stripe",
            "pay_stripe_2": "ویزا/مسترکارت و غیره.",
            "pay_webmoney_1": "پرداخت با WebMoney",
            "pay_webmoney_2": "WMZ/کارت روسیه و غیره.",

            "pay_success": "پرداخت موفقیت‌آمیز بود",
            "pay_fail": "پرداخت ناموفق بود",
            "column_activation_code": "کد فعال‌سازی",
            "column_days": "روز",

            "toast_webmoney_limit": "هنگام استفاده از WebMoney برای پرداخت، لطفاً توجه داشته باشید که ممکن است محدودیت‌هایی وجود داشته باشد. اگر پرداخت ناموفق بود، لطفاً سعی کنید مقدار را کاهش دهید.",

            "load_failed": "بارگیری ناموفق بود",
            "load_retry": "تلاش مجدد",
            "vip_charged_success": "شارژ VIP با موفقیت انجام شد. لطفاً حساب خود را بررسی کنید."
        }
    },
    tk: {
        message: {
            "welcome": 'AmanLink-a Hoş Geldiňiz',
            "welcome2": 'Siz 3 günlük mugt synagy alarsyňyz.',
            "welcome14": 'Siz 14 günlük mugt synagy alarsyňyz.',
            "welcomeMsg": 'Siziň üçin işleýän VPN.',
            "email": 'Başlamak üçin emailiňizi giriziň',
            "hint_email": 'Email',
            "download": 'GooglePlay-den Ýükle',
            "download2": 'Apk Faýlyny Ýükle',
            "msg_email_exist": 'Bu email salgysy eýýäm hasaba alyndy',
            "invitation_invalid": 'Çakylyk nädogry',
            "invitation_used_up": 'Çakylyklar hemmesi ulanyldy',
            "msg_download_other_platform": 'Çakylyk üstünlikli.',

            "title_account": 'AmanLink-de hasabyňyzy öçüriň',
            "desc_account": 'Email salgyňyzy we parolyňyzy üpjün etmek bilen, AmanLink-de hasabyňyzy öçürip bilersiňiz. Maglumatyňyz hemişelik öçüriler.',
            "hint_password": 'Parol',
            "delete": 'Hasaby Öçürmek',
            "msg_delete_success": 'Hasabyňyz öçürildi.',
            "msg_validate_error": 'Hasap barlagy şowsuz boldy',

            "buy_vip": "VIP satyn almak",
            "sendToFriend": "VIP işjeňleşdirme koduny satyn al ➔ Dostuňyza sowgat edip VIP möhletini alyň",
            "buyAmount": "Satyn alyş mukdary",
            "format_day": "Gün",
            "format_month": "Aý",
            "format_year": "Ýyl",
            "format_sumary": "Deň",

            "pay_payeer_1": "Payeer bilen töle",
            "pay_payeer_2": "USDT/Litecoin/Bitcoin we ş.m.",
            "pay_stripe_1": "Stripe bilen töle",
            "pay_stripe_2": "VISA/MASTER CARD we ş.m.",
            "pay_webmoney_1": "WebMoney bilen töle",
            "pay_webmoney_2": "WMZ/Russiýa kart we ş.m.",

            "pay_success": "Töleg üstünlikli",
            "pay_fail": "Töleg şowsuz",
            "column_activation_code": "Aktiwasiýa kody",
            "column_days": "Günler",

            "toast_webmoney_limit": "WebMoney bilen töleg edende, mümkin bolan çäklendirmeleri göz öňünde tutuň. Eger töleg başarısız bolsa, mukdary azaldyp görmäge synanyşyň.",

            "title": "AmanLink VPN ýerli satyjylary işe alýar",
            "greeting": "Hormatly potensial satyjy hyzmatdaş",
            "introduction": "Biz sizi biziň programma üpjünçiligimiziň aktiwleşdirme kodunyň ýerli satyjysy bolmaga çagyrýarys. Satyjy hökmünde, siz aktiwleşdirme kodlaryny biziň arzan bahamyza satyn alyp, ýerli walýuta bilen ýerli ulanyjylara satyp bilersiňiz. Aşakda biziň satyjylyk maksatnamamyza goşulmagyň peýdalary we hyzmatdaşlyk talaplary görkezilýär.",
            "benefitsTitle": "Hyzmatdaşlygyň Üstünlikleri",
            "directoryPromotion": "Satyjy Sanawy Mahabaty",
            "directoryDetails": "Biz sizi ýerli satyjy sanawymyza goşarys, ýerli ulanyjylar aktiwleşdirme kodlaryny satyn aljak bolanda, sizi göni tapyp bilerler.",
            "rebateProgram": "Arzanladyş Mehanizmi",
            "rebateDetails": "Satyjylar biziň özboluşly arzanladyş höweslendirme maksatnamamyzyň peýdasyny görerler: 10 sany aktiwleşdirme kodyny satyn alyň we goşmaça 2 sany kod alyň. 100 sany aktiwleşdirme kodyny satyn alyň we goşmaça 30 sany kod alyň. Bu arzanladyş maksatnamasy size has uly girdeji mümkinçiligini, çykdajylaryň azalmagyny we bazarda bäsdeşlik artykmaçlygyny hödürleýär.",
            "requirementsTitle": "Hyzmatdaşlyk Talaplary",
            "quickResponse": "Çalt Jogap",
            "responseDetails": "Satyn almak tejribesiniň gowy bolmagyny üpjün etmek üçin, ulanyjy isleglerine çalt jogap bermeli.",
            "friendlyCommunication": "Müşderiler Bilen Dostlukly Gatnaşyk",
            "communicationDetails": "Müşderiler bilen gowy gatnaşyk saklamaly we satyn almak ýa-da ulanmak döwründe ýüze çykyp biljek meseleleri çözmäge kömek etmeli.",
            "localCurrency": "Ýerli Walýuta Bilen Söwda",
            "currencyDetails": "Satyjy hökmünde, müşderilerden daşary ýurt walýutasy ýa-da sanly pul ulanmagyny talap etmän, ýerli walýuta bilen tölegleri goldamaly.",
            "policyTitle": "Hyzmatdaşlyk Syýasaty",
            "policyDetails": "Hyzmat hilini saklamak maksady bilen, satyjynyň müşderilerden aşa köp şikaýat almagy ýagdaýynda, biz hyzmatdaşlygy ýatyryp, satyjyny ýerli sanawdan aýyrarys.",
            "contactInfo": "Eger biziň ýerli satyjylarymyzyň biri bolmaga gyzyklanýan bolsaňyz, {'@'}normanou (Telegram) administratory bilen habarlaşmagyňyzy ýa-da mobile221106{'@'}gmail.com salgysyna hat ibermegiňizi haýyş edýäris.",

            "load_failed": "Ýüklemek başa barmady",
            "load_retry": "Gaýtadan synanyş",
            "vip_charged_success": "VIP töleg üstünlikli boldy. Hasabyňyzy barlaň."

        }
    },
    tr: {
        message: {
            "welcome": "AmanLink'e Hoş Geldiniz",
            "welcome2": "3 gün ücretsiz deneyim kazanacaksınız.",
            "welcome14": "14 gün ücretsiz deneyim kazanacaksınız.",
            "welcomeMsg": "Sizin için çalışan VPN.",
            "email": "Başlamak için e-posta adresinizi girin",
            "hint_email": "E-posta",
            "download": "GooglePlay'den İndir",
            "download2": "APK Dosyasını İndir",
            "msg_email_exist": "Bu e-posta adresi zaten kayıtlı",
            "invitation_invalid": "Davet geçersiz",
            "invitation_used_up": "Tüm davetler kullanıldı",
            "msg_download_other_platform": "Davet başarılı.",
            "title_account": "AmanLink'te hesabınızı silin",
            "desc_account": "E-posta adresinizi ve şifrenizi sağlayarak AmanLink'teki hesabınızı silebilirsiniz. Verileriniz kalıcı olarak silinecek.",
            "hint_password": "Şifre",
            "delete": "Hesabı Sil",
            "msg_delete_success": "Hesabınız silindi.",
            "msg_validate_error": "Hesap doğrulama başarısız",
            "buy_vip": "VIP Satın Al",
            "sendToFriend": "VIP etkinleştirme kodu satın al ➔ Bir arkadaşına hediye et ve VIP süresini kullandır",
            "buyAmount": "Satın Alma Miktarı",
            "format_day": "Gün",
            "format_month": "Ay",
            "format_year": "Yıl",
            "format_sumary": "Eşdeğer",

            "pay_payeer_1": "Payeer ile Öde",
            "pay_payeer_2": "USDT/Litecoin/Bitcoin vb.",
            "pay_stripe_1": "Stripe ile Öde",
            "pay_stripe_2": "VISA/MASTER CARD vb.",
            "pay_webmoney_1": "WebMoney ile Öde",
            "pay_webmoney_2": "WMZ/RUSYA KART vb.",

            "pay_success": "Ödeme Başarılı",
            "pay_fail": "Ödeme Başarısız",
            "column_activation_code": "Aktivasyon Kodu",
            "column_days": "Günler",

            "toast_webmoney_limit": "WebMoney ile ödeme yaparken, sınırlamalar olabileceğini lütfen unutmayın. Ödeme başarısız olursa, lütfen miktarı azaltmayı deneyin.",

            "title": "AmanLink VPN Yerel Satıcılar Alıyor",
            "greeting": "Değerli Potansiyel Satıcı Ortağımız",
            "introduction": "Sizleri yazılım aktivasyon kodu ürünümüz için yerel satıcı olmaya davet etmekten heyecan duyuyoruz. Bir satıcı olarak, bizden indirimli bir fiyatla aktivasyon kodu satın alma fırsatına sahip olacak ve bunları yerel para biriminizle yerel kullanıcılara satabileceksiniz. Aşağıda, satıcı programımıza katılmanın avantajları ve ortaklık için gereksinimler bulunmaktadır.",
            "benefitsTitle": "Ortaklık Avantajları",
            "directoryPromotion": "Satış Kataloğu Tanıtımı",
            "directoryDetails": "Yerel satıcılar kataloğumuza kaydedileceksiniz, böylece yerel kullanıcılar aktivasyon kodu satın almak istediklerinde sizi doğrudan katalogda bulabilecekler.",
            "rebateProgram": "İndirim Programı",
            "rebateDetails": "Satıcılar, benzersiz indirim teşvik programımızdan faydalanacaktır: 10 aktivasyon kodu satın alırsanız, 2 ek kod ücretsiz verilecektir. 100 aktivasyon kodu satın alırsanız, 30 ek kod ücretsiz verilecektir. Bu indirim programı, daha büyük bir kar marjı, düşürülmüş maliyetler ve pazarınızda rekabet avantajı sunar.",
            "requirementsTitle": "Ortaklık Gereksinimleri",
            "quickResponse": "Hızlı Yanıt",
            "responseDetails": "Kullanıcı taleplerine hızlı bir şekilde yanıt vermelisiniz, böylece pozitif bir satın alma deneyimi sağlanmış olur.",
            "friendlyCommunication": "Dostane İletişim",
            "communicationDetails": "Müşterilerle iyi ilişkiler kurmalı ve satın alma veya kullanım sırasında karşılaştıkları sorunları çözmelerine yardımcı olmalısınız.",
            "localCurrency": "Yerel Para Birimi ile İşlem",
            "currencyDetails": "Bir satıcı olarak, yerel para birimi ile işlem yapmalısınız, müşterilerin yabancı para birimi veya kripto para kullanmalarını gerektirmemelisiniz.",
            "policyTitle": "Ortaklık Politikası",
            "policyDetails": "Hizmet kalitesini korumak için, bir satıcı fazla müşteri şikayeti alırsa, ortaklık sonlandırılacak ve yerel satıcılar kataloğundan çıkarılacaktır.",
            "contactInfo": "Yerel satıcılardan biri olmakla ilgileniyorsanız, lütfen yöneticimiz @normanou (Telegram) ile iletişime geçin veya bize mobile221106@gmail.com adresinden e-posta gönderin.",

            "load_failed": "Yükleme başarısız oldu",
            "load_retry": "Tekrar dene",
            "vip_charged_success": "VIP yükleme başarılı. Lütfen hesabınızı kontrol edin."
        }
    },
    id: {
        message: {
            "welcome": "Selamat Datang di AmanLink",
            "welcome2": "Anda akan mendapatkan pengalaman gratis selama 3 hari.",
            "welcome14": "Anda akan mendapatkan pengalaman gratis selama 14 hari.",
            "welcomeMsg": "VPN yang bekerja untuk Anda.",
            "email": "Masukkan email Anda untuk memulai",
            "hint_email": "Email",
            "download": "Unduh dari GooglePlay",
            "download2": "Unduh File Apk",
            "msg_email_exist": "Alamat email ini sudah terdaftar",
            "invitation_invalid": "Undangan tidak valid",
            "invitation_used_up": "Semua undangan telah digunakan",
            "msg_download_other_platform": "Undangan berhasil.",
            "title_account": "Hapus akun Anda di AmanLink",
            "desc_account": "Dengan memberikan alamat email & kata sandi Anda, Anda dapat menghapus akun Anda di AmanLink. Data Anda akan dihapus secara permanen.",
            "hint_password": "Kata Sandi",
            "delete": "Hapus Akun",
            "msg_delete_success": "Akun Anda telah dihapus.",
            "msg_validate_error": "Validasi akun gagal",
            "buy_vip": "Beli VIP",
            "sendToFriend": "Beli kode aktivasi VIP ➔ Hadiahkan ke teman untuk menukarkan durasi VIP",
            "buyAmount": "Jumlah Pembelian",
            "format_day": "Hari",
            "format_month": "Bulan",
            "format_year": "Tahun",
            "format_sumary": "Setara dengan",

            "pay_payeer_1": "Bayar dengan Payeer",
            "pay_payeer_2": "USDT/Litecoin/Bitcoin dll.",
            "pay_stripe_1": "Bayar dengan Stripe",
            "pay_stripe_2": "VISA/KARTU MASTER dll.",
            "pay_webmoney_1": "Bayar dengan WebMoney",
            "pay_webmoney_2": "WMZ/KARTU RUSIA dll.",

            "pay_success": "Pembayaran Berhasil",
            "pay_fail": "Pembayaran Gagal",
            "column_activation_code": "Kode Aktivasi",
            "column_days": "Hari",

            "toast_webmoney_limit": "Saat menggunakan WebMoney untuk pembayaran, harap diperhatikan bahwa mungkin ada batasan. Jika pembayaran gagal, silakan coba kurangi jumlahnya.",

            "load_failed": "Gagal memuat",
            "load_retry": "Coba lagi",
            "vip_charged_success": "Isi ulang VIP berhasil. Silakan periksa akun Anda."
        }
    }
    // Add more languages here
};

// Create i18n instance with options
const i18n = createI18n({
    legacy: false, // you must specify 'legacy: false' option
    locale: navigator.language || navigator.userLanguage, // This will set the locale based on the browser's language
    fallbackLocale: 'en', // Fallback to English if the browser's language is not supported
    messages, // Your translations
});

createApp(App).use(ElementPlus).use(router).use(i18n).mount('#app')
